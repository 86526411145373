<template>
  <div class="modal fade" id="informationModal" tabindex="-1" role="dialog" aria-labelledby="informationModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="informationModalLabel"><i class="bi bi-app-indicator"></i> Graphisoft C++ ITech Challenge 2022 elődöntő</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-8">
              <h1>Kedves {{ userData.firstName }},</h1>
              <p>Köszöntünk a GRAPHISOFT C++ ITech Challenge 2022 elődöntőjében.</p>
              <p>Kérjük, hogy az itt található információkat olvasd el a rendszer használata előtt, később megtalálod az információk menüpont alatt.</p>
              <p class="small">frissítve: 2022.09.17 15:22:00</p>
              <h2><i class="bi bi-1-square"></i> Első lépés</h2>
              <p>A felületen elérhető a GraphiLand játékunk leírása, szabályokkal, összefüggésekkel, és minden fontos információval.</p>
              <p>Legelőször ezt olvassátok el. Érdemes figyelni a frissítés dátumára, előfordulhat, hogy egyes dolgokat pontosítani fogunk menet közben, de erről külön is tájékoztatunk Benneteket.</p>
              <h2><i class="bi bi-2-square"></i> Második lépés</h2>
              <p>A csapatotok Git repojában létrejött egy új branch, <kbd>main_graphiland</kbd> néven. Felkommitoltunk egy kiinduló kódot, amellyel el is indulhattok a játék fejlesztésében.</p>
              <p>A kódot a saját gépetekre Git segítségével kell lemásolni, melyről leírást a Git menüpontra kattintva (Clone, Checkout) értek el.</p>
              <h2><i class="bi bi-terminal"></i> Futtatások - hálózat</h2>
              <p>A példakódot a saját gépeteken fordítás után a következő paranccsal tudjátok futtatni:</p>
              <p><kbd>&lt;exe neve&gt; {{userData.gitUser}} {{userData.gameToken}} itechchallenge.dyndns.org 63155</kbd></p>
              <p>Hálózati futtatás esetén a válaszidő: 2 másodperc</p>
              <p>A hálózaton futtatott játékok bekerülnek a Játékok közé a fontosabb információkkal, és a lejátszás gombra kattintva meg is tudjátok jeleníteni.</p>
              <p>Ezek a futtatások képezik a tesztpályán való futtatásokat, amiknek az összesített eredményét weboldalunkon is követhettek. A továbbjutásba nem számítanak bele.</p>
              <p>Hibakeresésre ez a legmegfelelőbb módszer.</p>

              <h2><i class="bi bi-terminal"></i> Futtatások - Git</h2>
              <p>A <kbd>main_graphiland</kbd>-ra commitolás után a szerverünk észleli, hogy új kód került a repotokba, és elkezdi lefordítani, és futtatni.</p>
              <p>Git futtatás esetén a válaszidő: 250 milliszekundum</p>
              <p>A Git-re commitolt, majd futtatott játékok is bekerülnek a játékok közé, és ugyancsak beleszámítanak a teszt pontszámokba, de külön jelezzük, mely játék lett hálózaton és mely játék lett Git-en keresztül futtatva.</p>
              <p>A Git-es módszer lényege, hogy letesztelhessétek az éles futtatásokra, hogy a kódotok lefordul-e a mi szerverünkün, illetve hogy a csökkentett szerver válaszidő mellett is az elvárásaitoknak megfelelően működik.</p>
              <p>Indulásáról e-mailben fogunk értesíteni Benneteket.</p>
              <h2><i class="bi bi-dice-5"></i> Pontozás</h2>
              <p>A feladat leírásában találjátok a pontszámítás menetét.</p>
              <p>A lejátszott játékokat pályánként pontozzuk (az elért nyers pontokat utána GP40 szerint értékeljük), és az eredmények aloldalunkon összesítve is megjelenítjük.</p>
              <p class="small">Az előfordulhat, hogy egy friss futtatás új pontszáma csak pár perces késéssel jelenik meg az összesítésben.</p>

              <h2><i class="bi bi-sign-stop"></i> Tilos</h2>
              <p>Az elődöntő során a kódotoknak tilos:</p>
              <ul>
                <li>fájlműveleteket végrehajtani</li>
                <li>távoli elérést létesíteni</li>
                <li>futtatókörnyezetbe belenyúlni, annak működését felülírni</li>
                <li>futtatás közben felhasználói beavatkozásra reagálnia a kódotoknak</li>
              </ul>

              <h2><i class="bi bi-calendar4-range"></i> Időzítés</h2>
              <p>Megoldási határidő: 2022. november. 9 éjfél</p>
              <p class="small">A határidőn túl érkező megoldásokat nem fogadjuk el.</p>

              <h2><i class="bi bi-wind"></i> Éles futtatások, továbbjutás</h2>
              <p>A határidő vége után a legutoljára commitolt kódotokat fogjuk lefordítani és futtatni az éles pályákon (melyek eltérhetnek méretben és felépítésben is, de ugyanúgy 10 darab lesz).</p>
              <p>A határidő végéig a Ti felelősségetek olyan kódot feltölteni, ami fordul, és a csökkentett válaszidő mellett is az elvárásotok szerint működik. Mindkettőt a Git-es futtatás alkalmazásával tesztelni is tudtok.</p>
              <p>Az éles futtatás során szerzett pontokat külön gyűjtjük, a teszt futtatások nem számítanak bele.</p>
              <p>Az éles futtatásokon legjobban teljesítő 12 csapat jut tovább a döntőbe.</p>
              <p class="small">Esetleges pontegyezőség esetén fenntartjuk a jogot, hogy az érintett csapatok között újabb futtatásokat végezzünk (akár újabb pályákon is).</p>
              <p></p>
              <p>Jó programozást kívánunk!<br>
                Graphisoft C++ ITech Challenge csapata</p>
            </div>
            <div class="col-lg-4">
              <div class="card text-bg-primary mb-3">
                <div class="card-header text-white"><i class="bi bi-bug"></i> Bugfix</div>
                <div class="card-body">
                  <p class="card-text text-white">Előfordulhat, hogy a játékszerveren bizonyos logika nem a leírásnak megfelelően működik.</p>
                  <p class="card-text text-white">Ha ilyesmit észrevesztek, akkor írjatok nekünk, és igyekszünk kijavítani.</p>
                  <p class="card-text text-white">Ne alapozzatok hibás működésre.</p>
                  <p class="card-text text-white"><strong>Az elődöntő során a botok biztos fognak "okosodni", illetve a játékok alapján pálya módosítások is előfordulhatnak.</strong></p>
                </div>
              </div>
              <div class="card text-bg-secondary mb-3">
                <div class="card-header"><i class="bi bi-cloud-fog2"></i> Fordító</div>
                <div class="card-body">
                  <p>g++ 9.3.0</p>
                  <p>boost 1.70 header only library-k használhatóak.</p>
                  <p>-std=c++17 -Wall -O2 -static -pthread</p>
                  <p class="small">Ubuntu szerveren fordítunk.</p>
                </div>
              </div>
              <div class="card text-bg-secondary mb-3">
                <div class="card-header"><i class="bi bi-life-preserver"></i> Support</div>
                <div class="card-body">
                  <p>Ha kérdésed van, vagy szokatlan, nem megfelelő működést tapasztalsz, bátran írd meg nekünk az <a href="mailto:info@itechchallenge.hu">info@itechchallenge.hu</a> e-mail címre.</p>
                  <p>Lehetőség szerint 1-2 munkanapon belül válaszolunk.</p>
                  <p>Az összes csapatot érintő fontosabb változásokról e-mailben értesítünk Benneteket.</p>
                  <p class="small">Más csatornát légyszíves ne használj, mivel alapvetően nem figyeljük.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal"><i class="bi bi-caret-right-fill"></i> Hajrá, megnézem a feladatot</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Modal } from 'bootstrap'

export default {
  name: 'Information',
  props: ['initData'],
  data() {
    let userData = this.initData.user

    return {
      userData
    }
  },
  methods:{
    openModal(){
      this.modalInstance = new Modal(document.getElementById('informationModal'))
      this.modalInstance.show()
    },
  }
}
</script>


<style scoped>
h1{
  font-size: 2rem;
  line-height: 2rem;
}
</style>