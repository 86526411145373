<template>
  <div class="modal fade" id="gameDisplayModal" tabindex="-1" role="dialog" aria-labelledby="gameDisplayModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="gameDisplayModalLabel" style="margin-right: 3rem"><i class="bi bi-display"></i> Megjelenítő</h5>
          <button type="button" class="btn btn-primary" style="margin-right: 3rem" @click="fullScreen"><i class="bi bi-fullscreen"></i> teljes képernyő</button>
<!--          <span class="small">Ha a megjelenítőn szeretnéd látni az eredményeket, használj Chrome-t.</span>-->
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <iframe id="gameDisplay" src="/display/index.html"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Modal } from 'bootstrap'

export default {
  name: 'GameDisplay',
  props: ['initData'],
  data() {
    return {
      modalInstance : null
    }
  },
  methods:{
    openModal(){
      this.modalInstance = new Modal(document.getElementById('gameDisplayModal'))
      this.modalInstance.show()
    },
    closeModal(){
      this.modalInstance = new Modal(document.getElementById('gameDisplayModal'))
      this.modalInstance.hide();
    },
    start(data){
      document.getElementById('gameDisplay').contentWindow.startVisualizer(data)
    },
    fullScreen(){
      document.querySelector('#gameDisplay').requestFullscreen()
    }
  }
}
</script>


<style scoped>
#gameDisplay{
  width: 100%;
  height: 1160px;
  position: relative;
  top: -105px;
  margin: 0 auto;
  transform: scale(0.85);
  /*transform-origin: 0 0;*/
}
@media (min-width: 1200px){
  .modal-xl {
    --bs-modal-width: 1920px;
  }
}
</style>