<template>
  <Header v-if="initData.initLoader===false" :initData = "initData"></Header>
  <Loader :initData="initData"/>
  <div class="alert-deck">
    <Alerts :alerts="alert" v-for="alert in initData.alerts"/>
  </div>
  <router-view v-if="initData.initLoader===false" :initData = "initData"/>
  <Information v-if="initData.initLoader===false" :initData = "initData"></Information>
  <FinalInformation v-if="initData.initLoader===false" :initData = "initData"></FinalInformation>
  <GitModal v-if="initData.initLoader===false" :initData = "initData"></GitModal>
  <Footer v-if="initData.initLoader===false"></Footer>
</template>

<script>
import {reactive} from 'vue'
import Loader from "./components/Loader"
import Alerts from "./components/Alerts"
import Header from "./components/Header"
import Footer from "./components/Footer"
import {Response} from '@/classes/response.js'
import Information from "@/views/Information";
import FinalInformation from "@/views/FinalInformation";
import GitModal from "@/views/GitModal";

export default {
  name: 'App',
  components: {
    FinalInformation, Information, GitModal, Loader, Alerts, Header, Footer
  },
  data(){
    // DEFAULT INIT DATA
    const initData = reactive({
      'user' : null,
      'data' : null,
      'initLoader' : true,
      'loader' : false,
      'alerts' : []
    })
    const classes = ['.modal-backdrop']

    return {
      initData
    }
  },
  created(){
    this.init()
  },
  methods:{
    init(){
      this.$axios.get('/v2/game/')
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              console.log(responseData.data)
              this.initData.user = responseData.data.user
              this.initData.data = responseData.data.data

              let histories = this.initData.user.histories
              if(histories.length===0){
                this.initData.user.lastRunDateTime = null
              }else{
                this.initData.user.lastRunDateTime = histories[0].createDateTime
              }
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.initLoader = false
          });

    }
  }
}
</script>
<style>

</style>
