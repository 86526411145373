<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="sticky-top">
            <div class="row">
              <div class="col-12">
                <button class="btn btn-primary float-end mb-3" @click="visibleFileUpload"><i class="bi bi-file-image"></i> Fájl lejátszás</button>
                <h1><i class="bi bi-terminal"></i> Játékok</h1>
                <div class="card" v-if="fileUploadVisible">
                  <div class="card-body">
                    <p>Bármely display.log-ot letudjátok itt játszani.</p>
                    <div class="input-group mb-3">
                      <input type="file" class="form-control" id="fileUploadInput" v-on:change="onFileChange">
                    </div>
                  </div>
                </div>
                <p class="small">A futtatások részletes eredményeit (compile.log, results.log, display.log) a <a :href="gitWebIdeUrl.replace('/-/ide/project','')+userData.gitUser+'/-/commits/main_log/'" target="_blank">git repotok main_log branch</a>ében is nyomon követhetitek.</p>
              </div>
              <div class="col-3 mb-3">
                <select class="form-control" v-model="selectLevel" @change="taskFilter">
                  <option value="0">Összes pálya</option>
                  <option value="1">1. pálya</option>
                  <option value="2">2. pálya</option>
                  <option value="3">3. pálya</option>
                  <option value="4">4. pálya</option>
                  <option value="5">5. pálya</option>
                  <option value="6">6. pálya</option>
                  <option value="7">7. pálya</option>
                  <option value="8">8. pálya</option>
                  <option value="9">9. pálya</option>
                  <option value="10">10. pálya</option>
                </select>
              </div>
              <div class="col-9">
                <button class="btn btn-primary float-end" @click="getNewest()"><i class="bi bi-arrow-clockwise"></i> Frissítés</button>
              </div>
              <div class="col-12 table-responsive history">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>Azonosító</th>
                    <th>Pálya</th>
                    <th>Teszt/éles</th>
                    <th>Hálózat/Git</th>
                    <th>Git hash</th>
                    <th>Pontszám</th>
                    <th>Futtatás vége</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(history,index) in histories">
                    <tr>
                      <td>#{{history.runId}}</td>
                      <td>{{history.level}}. pálya</td>
                      <td><span v-if="history.isTest==1">teszt</span><span v-else>éles</span></td>
                      <td><span v-if="history.isGit==1">Git</span><span v-else>hálózat</span></td>
                      <td><a :href="gitWebIdeUrl.replace('/-/ide/project','')+userData.gitUser+'/-/commit/'+history.gitHash" target="_blank" title="Commit a GitLab felületen"><i class="bi bi-git"></i></a> {{history.gitHash}}</td>
                      <td>{{history.sumScore}}</td>
                      <td>{{history.createDateTime}}</td>
                      <td @click="playGame(history.gitHash)"><i class="bi bi-play-btn"></i></td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="userData.isFinal===false">
          <button class="btn btn-primary float-end mb-3" @click="download()"><i class="bi bi-download"></i> Letöltés</button>
          <div class="task-text" v-html="semiFinalTask"></div>
        </div>
        <div class="col-md-6" v-if="userData.isFinal && semiFinalTaskVisible" >
          <button class="btn btn-secondary float-end mb-3" @click="taskChange()"><i class="bi bi-2-circle"></i> Döntő</button>
          <button class="btn btn-primary float-end mb-3" @click="download()"><i class="bi bi-download"></i> Letöltés</button>
          <div class="task-text" v-html="semiFinalTask"></div>
        </div>
        <div class="col-md-6" v-if="userData.isFinal && semiFinalTaskVisible===false">
          <button class="btn btn-secondary float-end mb-3" @click="taskChange()"><i class="bi bi-1-circle"></i> Elődöntő</button>
          <button class="btn btn-primary float-end mb-3" @click="downloadFinal()"><i class="bi bi-download"></i> Letöltés</button>
          <div class="final-task-text" v-html="finalTask"></div>
        </div>
      </div>
    </div>
  </main>
  <GameDisplay></GameDisplay>
</template>

<script>
import {Response} from '@/classes/response';
import {Validate} from "@/classes/validate";
import Information from "@/views/Information";
import FinalInformation from "@/views/FinalInformation";
import GameDisplay from "@/views/GameDisplay";

export default {
  name: 'Game',
  props: ['initData'],
  components: {
    GameDisplay,
    Information,
    FinalInformation
  },
  data(){
    let semiFinalTask = this.initData.data.semifinal.task
    let finalTask = this.initData.data.final.task
    let histories = this.initData.user.histories
    let gitWebIdeUrl = this.initData.data.gitWebIdeUrl
    let userData = this.initData.user

    return {
      semiFinalTask,
      finalTask,
      histories,
      gitWebIdeUrl,
      userData,
      selectLevel : 0,
      Information,
      FinalInformation,
      fileUploadVisible : false,
      semiFinalTaskVisible :false
    }
  },
  mounted() {

  },
  computed:{

  },
  watch:{

  },methods:{
    taskChange(){
      if(this.semiFinalTaskVisible===false){
        this.semiFinalTaskVisible = true
      }else{
        this.semiFinalTaskVisible = false
      }
    },
    download(){
      let elementHtml = document.querySelector('.task-text').innerHTML;
      let link = document.createElement('a');

      link.setAttribute('download', 'Graphiland_ITech_Challenge_2022.html');
      link.setAttribute('href', 'data:text/html;charset=utf-8,' + encodeURIComponent(elementHtml));
      link.click();
    },downloadFinal(){
      let elementHtml = document.querySelector('.final-task-text').innerHTML;
      let link = document.createElement('a');

      link.setAttribute('download', 'Graphiland_ITech_Challenge_2022_final.html');
      link.setAttribute('href', 'data:text/html;charset=utf-8,' + encodeURIComponent(elementHtml));
      link.click();
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;

      let reader = new FileReader();
      reader.readAsText(files[0], "UTF-8")
      reader.onload = function (evt) {
        GameDisplay.methods.openModal()
        GameDisplay.methods.start(evt.target.result)
        document.getElementById('fileUploadInput').value = ''
      }

    },
    playGame(gitHash){
      let formData = new FormData()
      formData.append('git_hash', gitHash)

      this.initData.loader = true
      this.$axios.post('/v2/game/playGame',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              let displayData = responseData.data.display
              if(displayData!==false){
                GameDisplay.methods.openModal()
                GameDisplay.methods.start(responseData.data.display)
              }

            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    },
    visibleFileUpload(){
      if(this.fileUploadVisible===true){
        this.fileUploadVisible = false
      }else{
        this.fileUploadVisible = true
      }
    },
    taskFilter(){
      let selectLevel = this.selectLevel
      let histories = this.initData.user.histories
      if(selectLevel!=='0'){
        this.histories = histories.filter(function (el) {return el.level===selectLevel})
      }else{
        this.histories = histories
      }

    },
    getNewest() {
      console.log('get newest')

      let formData = new FormData()
      formData.append('dateTime', this.initData.user.lastRunDateTime)

      this.initData.loader = true
      this.$axios.post('/v2/game/newest',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              let histories = responseData.data.histories
              if(histories.length>0){
                this.initData.user.histories.splice(0,0,...histories)
                this.initData.user.lastRunDateTime = histories[0].createDateTime
              }
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });

    }
  }
}
</script>
<style scoped>
main{
  padding-top: 1rem
}
.container{
  padding-bottom: 8rem;
}
@media (min-width: 1400px){
  .container {
    max-width: 100%;
  }
}

.row{
  position: relative;
}
.history{
  max-height: 650px;
  overflow-y: scroll;
}
/* purgecss start ignore */
:deep(.task-text img){
  max-width: 100%;
}
:deep(.final-task-text img){
  max-width: 100%;
}
/* purgecss end ignore */
</style>